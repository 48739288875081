













import { Component, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  navigateMaps() {
    window.location.href = "https://maps.app.goo.gl/YeKn7usWHGaX4GuF6";
  }
}
